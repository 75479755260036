import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Icon } from 'components/icon'
import { Text } from 'components/Typography'
import { motion } from 'framer-motion'
import { RemoveScroll } from 'react-remove-scroll'
import { BREAKPOINTS, pxToRem, StyledLinkProps } from 'utils/styled'

export const SNavigation = styled.nav`
  display: flex;
  @media ${BREAKPOINTS.MD.min} {
    & > :not(:last-child) {
      margin-right: 3rem;
    }
  }
  @media ${BREAKPOINTS.MD.max} {
    flex-direction: column;
    & > :not(:last-child) {
      margin-bottom: 2rem;
    }
  }
`

export const SPathNameContainer = styled.div`
  display: flex;
  align-items: center;
`

export const SRightArrow = styled(Icon)`
  transform: rotate(270deg);
  display: none;
  width: 0.7rem;
  height: 0.7rem;
  margin-left: auto;

  path {
    stroke: black;
    stroke-width: 1.5px;
  }
`

export const STitle = styled(Text)`
  color: black;
`

export const SChildPathsWrapper = styled.div<{ show?: boolean; noTransition?: boolean }>`
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  padding-top: 1.9rem;
  z-index: 99;
  visibility: ${(p) => (p.show ? 'visible' : 'hidden')};
  opacity: ${(p) => (p.show ? '1' : '0')};
  ${(p) =>
    !p.show
      ? css`
          pointer-events: none;
        `
      : ''}

  transition: visibility 0.2s, opacity 0.2s linear;
  ${(p) =>
    p.noTransition
      ? css`
          transition: none;
        `
      : ''}
  transition-delay: ${(p) => (p.show ? '0.1s' : '0.3s')};
`

export const SChildPath = styled.a<{ isActive?: boolean }>`
  display: inline-grid;
  grid-template-columns: 1.5rem auto;
  grid-gap: 1rem;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;

  ${({ isActive }) =>
    isActive
      ? css`
          & ${STitle} {
            text-decoration: underline;
          }
        `
      : css`
          &:hover {
            & ${STitle} {
              text-decoration: underline;
            }

            & ${SRightArrow} {
              display: inline-block;
            }
          }
        `}
`

export const SChildPathsContainer = styled(motion.div)`
  max-width: ${pxToRem(1000)}rem;
  display: grid;
  grid-template-columns: repeat(2, ${pxToRem(270)}rem);

  grid-gap: 2rem;
  background-color: white;
  overflow: hidden;
  padding: 3rem;
  box-shadow: var(--card-shadow);

  @media ${BREAKPOINTS.MD.max} {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    box-shadow: none;
    padding: 0;
    grid-gap: 0;
    & > *:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`

export const SPath = styled.a<
  StyledLinkProps & {
    hasUrl?: boolean
    isEncouragedAction?: boolean
    isActive?: boolean
  }
>`
  text-decoration: none;
  display: flex;
  position: relative;

  & ${SPathNameContainer} {
    > span {
      transition: color 0.1s;
      transition-delay: 0.1s;
    }
  }

  ${({ hasUrl }) =>
    hasUrl
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: default;
        `}
  ${({ isEncouragedAction, isActive }) => css`
    & ${SPathNameContainer} {
      > span {
        ${isActive
          ? css`
              font-weight: bold;
              color: black;
            `
          : null}
        ${isEncouragedAction && !isActive ? 'color: var(--c-blue500);' : null}
      }
    }
  `}
    &:hover {
    & ${SPathNameContainer} {
      > span {
        transition-delay: 0.1s;
        color: black;
      }
    }
  }

  // Increase active area

  &:after {
    content: '';
    position: absolute;
    top: -1rem;
    bottom: -1rem;
    left: -1rem;
    right: -1rem;
  }
`

export const STextColumn = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 0.5rem;
`

export const STitleRow = styled.div`
  display: flex;
  align-items: center;

  @media ${BREAKPOINTS.MD.max} {
    justify-content: unset;
    & > span {
      margin-right: 1rem;
    }
  }
`

export const SMobileNavigationModal = styled(motion.div)`
  width: 100vw;
  height: calc(100% - var(--h-landing-page-header));
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  padding: 1rem 1.5rem;
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    padding-bottom: calc(env(safe-area-inset-bottom) + 1rem);
    transition: padding-bottom 1s;
  }

  background-color: white;
`

export const SModalActionButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${pxToRem(150)}rem, 1fr));
  grid-gap: 1rem;
  margin-top: auto;
`

export const SMobileNavigationModalContent = styled(RemoveScroll)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  overflow-y: scroll;

  & ::-webkit-scrollbar {
    display: none;
  }
`

export const SModalPath = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
`

export const SModalPathNameContainer = styled(SPathNameContainer)<{ isOpen?: boolean }>`
  justify-content: space-between;
  margin-bottom: 1rem;
  cursor: pointer;

  & svg {
    transition: all 0.1s linear;
    ${({ isOpen }) => (isOpen ? 'transform: rotate(180deg);' : null)}
  }
`

export const SCornerstonesContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: minmax(10rem, 1fr) minmax(8rem, 1fr) minmax(13rem, 1fr) minmax(10rem, 1fr);
  grid-gap: 3rem;

  max-width: min(100vw, ${pxToRem(1000)}rem);

  background-color: white;
  overflow: hidden;
  padding: 3rem;
  box-shadow: var(--card-shadow);

  @media ${BREAKPOINTS.MD.max} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    box-shadow: none;
    padding: 0;
  }
`

export const SCornerstone = styled.div`
  display: flex;
  flex-direction: column;
  @media ${BREAKPOINTS.MD.max} {
    margin-bottom: 1rem;
  }
`

export const SCornerstoneTitle = styled.a`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  text-decoration: none;
  text-decoration-color: currentColor;

  &:hover {
    text-decoration: underline;
  }

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

export const SCornerstoneLinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const _SCornerstoneChildPath = styled(Text)`
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
  }
`

export const SCornerstoneChildPath = _SCornerstoneChildPath.withComponent('a')
